<template>
  <div class="flex h-screen">
    <div
      class="w-full"
    >
      <p class="clock">
        {{ currentTime.toLocaleTimeString() }}
      </p>
    </div>
  </div>
</template>
<script type="module">
import { useCurrentTime } from "@/assets/clock/useCurrentTime";

export default {
  name: "CurrentTimeExample",
  setup() {
    const { currentTime } = useCurrentTime();

    return { currentTime };
  },
};
</script>

<style lang="scss" scoped>
.clock {
  color: black;
  margin: 0;
}

@media (max-width: 535px) {
  .clock {
    font-size: 35px;
  }
}
</style>
