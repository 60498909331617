<template>
  <div class="my-wrapper">
  <div class="content-data">
    <div class="header">
      <div class="logo d-flex align-center justify-content-between">
        <div>
          <img class="logo__img" :style="{ 'height': imgHeightCounter + 'px'}" :src="require('@/assets/images/newsend_new_logo_light.svg')" />
        </div>
        <div>
          <flag :style="{ 'height': flagHeightCounter + 'px', 'width': flagWidthCounter + 'px'}"></flag>
        </div>
      </div>
    </div>
    <div class="main-content">
      <VCard style="width: 100%" elevation="0">
        <VCardText max-height="300">
          <v-data-table
            elevation="0"
            :headers="cronHeaders"
            :items="cronItems"
            :items-per-page="cronItems.length"
            item-key="email"
            hide-default-footer
            locale="uk"
            dense
            loader-height="1"
            height="420px"
            fixed-header
            :no-data-text="$t('table.noData')"
          >
            <!-- eslint-disable-next-line  -->
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.task }}</td>
                <td v-if="item.command">{{ item.command }}</td>
                <td v-if="!item.command">
                  <v-text-field
                    dense
                    hide-details="auto"
                    color="success"
                    prepend-icon="mdi-package-variant-closed-check"
                    v-model="receipt"
                    @keypress="validationForBarcode($event)"
                    @paste="validationForBarcode($event)"
                  >
                  </v-text-field></td>
                <td v-if="item.command">
                  <v-btn
                    class="btn-edit sm mr-3"
                    color="primary"
                    dense
                    small
                    :disabled="loading"
                    @click="handleButtonClick(item)"
                  >
                    <v-icon dense>{{ getButtonIcon(item.task) }}</v-icon>
                  </v-btn>
                </td>
                <td v-if="!item.command">
                  <v-btn
                    class="btn-edit sm mr-3"
                    color="primary"
                    dense
                    small
                    :disabled="loading || receipt.length !== 36"
                    @click="handleButtonClick(item)"
                  >
                    <v-icon dense>{{ getButtonIcon(item.task) }}</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </VCardText>
      </VCard>
    </div>
    <div class="footer">
    </div>
  </div>
    <InfoCronDialog
      :visible="showDialog"
      @close="
        showDialog = false;
      "
    >
      <template v-slot:description>
        {{ response }}
      </template>
    </InfoCronDialog>
    <ConfirmDialogSlots
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @onConfirm="
        resetReceipt();
        visibleConfirm = false;
      "
      @onCancel="visibleConfirm = false;"
      :typeAlert="'success'"
    >
      <template v-slot:header>
        {{ $t("Скидання даних") }}
      </template>
      <template v-slot:description>
        {{ $t("Ви впевнені що хочете скинути дані чеку?") }}
      </template>
    </ConfirmDialogSlots>
  </div>
</template>

<script>
import DigitalClock from "@/assets/clock/DigitalClock.vue";
import Flag from "@/assets/flag/ukrainianFlag.vue";
import notifications from '../../mixins/notifications';
import depotService from "@/services/request/depot/depotService";
import loader from '../../mixins/loader';
import InfoCronDialog from "@/components/dialog/InfoCronDialog.vue";
import supplyService from "../../services/request/supply/supplyService";
import crmForms from "../../services/request/crm/crmForms";
import ConfirmDialogSlots from "@/components/dialog/ConfirmDialog.vue";
import {validationForBarcode} from "../../mixins/helpers";

export default {
  data: () => ({
    cronItems: [],
    cronHeaders: [],
    response: [],
    receipt: '',
    showDialog: false,
    loading:false,
    visibleConfirm: false
  }),
  mixins: [notifications, loader],
  methods: {
    validationForBarcode,
    renderCronTable() {
      this.cronItems = [
        {
          task: 'Запустити інтеграцію',
          command: 'https://api.dev.mvsb.top/api/v1/modules/auto/run'
        },
        {
          task: 'Відправити клієнту',
          command: 'https://api.dev.mvsb.top/api/v1/deports/cron/send'
        },
        {
          task: 'Опрацювати статуси вхідних відправок',
          command: 'https://api.dev.mvsb.top/api/v1/deports/cron/cron?cmd=npupdate&type=in&offset=auto'
        },
        {
          task: 'Опрацювати статуси вихідних відправок',
          command: 'https://api.dev.mvsb.top/api/v1/deports/cron/cron?cmd=npupdate&type=out&offset=auto'
        },
        {
          task: 'Скинути дані чека',
          uuid: ''
        }
      ];
    },
    renderCronHeaderTable() {
      this.cronHeaders = [
        { text: 'Завдання', value: "task" },
        { text: 'Команда', value: "command" },
        { text: 'Керування', value: "btn" }
      ];
    },
    async startAutoRun(){
      try{
        this.loading = true;
        const item = await depotService.startAutoRun();
        this.response = item
        this.showDialog = true;
        this.loading = false;
      }catch(e){
        this.setErrorNotification(e)
      }
    },
    async sendToClient(){
      try {
        this.loading = true;
        const item = await depotService.sendToClient();
        this.response = item.processed
        this.showDialog = true;
        this.loading = false;
      } catch (e) {
        this.setErrorNotification(e)
      }
    },
    async updateIn(){
      try {
        this.loading = true;
        const item = await depotService.updateIn();
        this.response = item
        this.showDialog = true;
        this.loading = false;
      } catch (e) {
        this.setErrorNotification(e)
      }
    },
    async updateOut(){
      try {
        this.loading = true;
        const item = await depotService.updateOut();
        this.response = item
        this.showDialog = true;
        this.loading = false;
      } catch (e) {
        this.setErrorNotification(e)
      }
    },
    async resetReceipt(){
      try {
        this.loading = true;
        await crmForms.resetReceipt(this.receipt);
        this.receipt = '';
        this.loading = false;
      } catch (e) {
        this.setErrorNotification(e)
      }
    },
    getButtonIcon(task) {
      if (task === 'Запустити інтеграцію') {
        return 'mdi-play';
      } else if (task === 'Відправити клієнту') {
        return 'mdi-send';
      } else if (task === 'Опрацювати статуси вхідних відправок') {
        return 'mdi-package-variant-closed-plus';
      } else if (task === 'Опрацювати статуси вихідних відправок') {
        return 'mdi-package-variant-closed-minus';
      } else if (task === 'Скинути дані чека') {
        return 'mdi-restore';
      }
    },
    handleButtonClick(item) {
      if (item.task === 'Запустити інтеграцію') {
        this.startAutoRun();
      } else if (item.task === 'Відправити клієнту') {
        this.sendToClient();
      } else if (item.task === 'Опрацювати статуси вхідних відправок') {
        this.updateIn();
      } else if (item.task === 'Опрацювати статуси вихідних відправок') {
        this.updateOut();
      } else if (item.task === 'Скинути дані чека') {
        this.visibleConfirm = true;
      }
    },
  },
  components: {
    InfoCronDialog,
    Flag,
    DigitalClock: DigitalClock,
    ConfirmDialogSlots,
  },
  watch: {
    receipt: {
      handler() {
        console.log(this.receipt)
      }
    }
  },
  mounted() {
    this.renderCronHeaderTable();
    this.renderCronTable();
  },
  computed: {
    imgHeightCounter() {
      return window.innerWidth - (window.innerWidth / 100) * 97
    },
    flagHeightCounter() {
      return window.innerWidth - (window.innerWidth / 100) * 97
    },
    flagWidthCounter() {
      return window.innerWidth - (window.innerWidth / 100) * 96
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  height: 100%;
}

.my-wrapper {
  min-height: 100vh;
  position: relative;
}

.content-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  gap: 100px;
  background: #fff;
  height: calc(100vh);
  overflow-y: auto;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
  }

  .main-content {
    display: flex;
    align-items: center;
    gap: 30px;
    align-self: stretch;
    margin-top: auto;

    .amount-text {
      display: flex;
      padding: 24px;
      min-width: 250px;
      min-height: 200px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 32px;
      flex: 1 0 0;
      border-radius: 10px;
      border: 1px solid #F2F4F5;
      background: var(--w, #FFF);
      box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.25);

      &:hover {
        font-style: italic;
        cursor: pointer;
        background: #F8F8FF;
      }

      .info-item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        align-self: stretch;

        .info-item-text {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          color: var(--bl-text, #1d1d1d);
          leading-trim: both;
          text-edge: cap;
          font-family: Roboto;
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          min-height: 37px;
        }

        .info-item-analytic {
          color: var(--gr, #4fae3b);
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }

      .amount-item {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;

        .amount-item-img__run {
          display: flex;
          padding: 12px;
          justify-content: center;
          align-items: center;
          gap: 12px;
          border-radius: 8px;
          background: rgba(9, 80, 160, 0.10);
        }

        .amount-item-img__send {
          display: flex;
          padding: 12px;
          justify-content: center;
          align-items: center;
          gap: 12px;
          border-radius: 8px;
          background: rgba(215, 72, 0, 0.10);
        }

        .amount-item-img__income {
          display: flex;
          padding: 12px;
          justify-content: center;
          align-items: center;
          gap: 12px;
          border-radius: 8px;
          background: rgb(245,245,245);
        }
        .amount-item-img__outcome {
          display: flex;
          padding: 12px;
          justify-content: center;
          align-items: center;
          gap: 12px;
          border-radius: 8px;
          background: rgb(245,245,245);
        }

      }

    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    align-self: stretch;
    margin-top: auto;
  }

  .clock {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 24px;
    border-radius: 10px;
    border: 1px solid #F8FAFB;
    background: #FFF;
  }

}
@media (max-width: 900px) {
  .content-data {
    padding: 5px 10px;
    gap: 10px;

    .main-content {
      flex-direction: column;
      gap: 5px;

      .amount-text {
        width: 100%;
      }

    }

  }
}
</style>
