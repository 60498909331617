<template>
  <div class="flag" >
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>
    <div class="column"></div>

  </div>
</template>

<script>
export default {
  name: "Flag",
};
</script>

<style lang="scss" scoped>
body {
  background: lightgray;
  margin: 50px;
}

.flag {
  width: 100px;
  height: 70px;
  display: flex;
  background: none;
}

.column {
  flex-grow: 1;
  width: 100%;
  background: linear-gradient(
      to bottom,
      hsl(206deg, 100%, 45%) 0% 50%,
      hsl(50deg, 100%, 50%) 50% 100%
  );

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    overflow: hidden;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
  }
  animation: upDown 500ms infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
}

@keyframes upDown {
  from {
    transform: translateY(8px);
  }
  to {
    transform: translateY(-8px);
  }
}

@for $i from 1 through 30 {
  .column:nth-child(#{$i}) {
    animation-delay: $i*20ms;
  }
}
</style>
