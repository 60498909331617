<template>
  <VDialog v-model="visibility" max-width="400" :transition="$dialogTransition">
    <VCard class="pt-4 ml-3 mb-3" max-width="600" style="position: fixed; bottom: 0; left: 0; max-height: 80vh; overflow: scroll;">
      <VCardText class="d-flex">
        <span class="text-left d-block" :class="{ 'text-caption': $vuetify.breakpoint.xs }">
          <slot name="description"></slot>
        </span>
        <VBtn icon class="mb-1 py-0 ml-auto">
          <v-icon @click="copyToCLipboard" class="icon-btn mb-0 py-0">mdi-content-copy</v-icon>
        </VBtn>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>

import notifications from "@/mixins/notifications";

export default {
  name: "InfoCronDialog",
  mixins: [notifications],
  data: () => ({
    items: ''
  }),
  props: {
    visible: {
      required: true,
      type: Boolean
    }
  },
  methods: {
    async copyToCLipboard() {
      try {
        const descriptionContent = this.$slots.description[0].text;
        await navigator.clipboard.writeText(descriptionContent);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e)
      }
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    }
  }
};
</script>

<style scoped></style>
